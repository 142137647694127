<script>
export default {
  name: 'LogoHyattZiva',
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :class="{ 'fill-current': fill }"
    viewBox="30 30 540 190"
    style="enable-background: new 0 0 1366 768"
    xml:space="preserve"
  >
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="548.95,104.45 201.42,104.45 201.42,103.42 548.95,103.42 548.95,104.45 "
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M264.86,162.27l13.56-36.14l13.26,36.14l-1.7-0.01c-0.11,0-2.66-0.03-3.54-2.66l-2.83-7.45h-10.74l-0.06,0.14 l-0.01-0.01c-0.83,2.15-2.77,7.16-2.84,7.35c-0.64,2.01-2.44,2.59-3.39,2.61L264.86,162.27 M278.36,137.6l-3.98,10.56h7.83 L278.36,137.6 M343.33,162.27h-1.52c-1.3,0-2.69-1.02-2.69-2.91v-28.32h-7.67c-1.52,0-2.36-1.3-2.39-2.51l-0.02-1.55h22 c1.52,0,2.36,1.31,2.39,2.53l0.02,1.53h-10.12V162.27 M310.87,162.27h-1.52c-1.3,0-2.69-1.02-2.69-2.91v-28.32h-7.68 c-1.52,0-2.36-1.3-2.39-2.51l-0.02-1.55h22c1.52,0,2.36,1.31,2.39,2.53l0.02,1.53h-10.12V162.27 M247.32,162.27l-1.52-0.01v-16.28 l-10.81-19l2.32-0.01c0.75,0,2.6,0.19,3.58,1.99c0.68,1.24,7.06,12.59,7.06,12.59l7.06-12.59c1.09-1.95,3.62-1.98,3.65-1.98l2.13,0 L250,145.96v13.4C250,161.25,248.62,162.27,247.32,162.27 M223.39,162.27l-1.52-0.01c-1.31-0.03-2.65-1-2.65-2.78l0-12.87H205.6 v12.74c0,1.91-1.37,2.91-2.72,2.91l-1.45-0.04l0-35.27l1.5,0c1.33,0.03,2.68,1,2.68,2.78l0,12.84h13.63v-12.71 c0-1.89,1.38-2.91,2.67-2.91l1.49,0.05V162.27"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M439.01,162.93L425.82,127l1.69,0.01c0.98,0,2.83,0.56,3.52,2.63c0.02,0.07,6.54,17.52,7.85,21.01l0.19,0.51 l0.2-0.51c0.33-0.85,8.05-20.79,8.13-21.05c0.81-2.52,3.27-2.59,3.37-2.59l1.73-0.02L439.01,162.93"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M413.28,162.27l-1.19-0.01v-32.25c0-2.19,1.76-3.02,2.94-3.05l1.2-0.02v32.08 C416.23,161.25,414.7,162.27,413.28,162.27"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M454.21,162.27l13.56-36.14l13.26,36.14l-1.7-0.01c-0.11,0-2.66-0.03-3.54-2.66l-2.82-7.45h-10.74l-2.9,7.49 c-0.64,2.01-2.44,2.59-3.39,2.61L454.21,162.27 M467.7,137.6l-3.98,10.56h7.83L467.7,137.6"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M400.48,162.28h-24.74l18.74-31.28h-13.35c-1.75,0-2.71-1.33-2.71-2.58l-0.01-1.44h23.14l-18.72,31.31h14.91 c0.79,0,1.44,0.23,1.94,0.7c0.8,0.76,0.81,1.78,0.81,1.9V162.28"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M445.71,80.86h-13.19V45.49h1.23c1.21,0,3,0.83,3,3.1v28.2h11.66v1.29l-0.32,1.34 C447.66,80.32,446.76,80.86,445.71,80.86"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M493.34,80.83l-1.11-0.03V45.51h6.97c7.8,0,13.25,4.9,13.25,11.93c0,6.46-4.22,11.09-11.02,12.09l-0.48,0.07 l13.45,11.12l-3.11,0.1c-0.69,0-3.06-0.1-4.65-1.41c-1.24-1.02-10.44-8.77-10.44-8.77v7.03C496.2,79.99,494.49,80.83,493.34,80.83  M496.2,49.33V66h2.65c5.88,0,9.54-3.25,9.54-8.47c0-4.98-3.67-8.2-9.36-8.2H496.2"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M415.05,80.84l-1.19-0.01V48.53c0-2.19,1.76-3.02,2.94-3.05l1.2-0.02V77.6C418,79.83,416.47,80.84,415.05,80.84 "
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M454.04,80.85l13.56-36.14l13.26,36.14l-1.7-0.01c-0.11,0-2.67-0.03-3.54-2.66l-2.83-7.45h-10.74l-2.91,7.49 c-0.64,2.01-2.44,2.59-3.39,2.61L454.04,80.85 M467.53,56.17l-3.98,10.56h7.83L467.53,56.17"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M522.13,80.85l13.56-36.14l13.26,36.14l-1.69-0.01c-0.11,0-2.67-0.03-3.54-2.66l-2.83-7.45h-10.75l-0.05,0.13 c-0.83,2.14-2.78,7.16-2.84,7.35c-0.64,2.01-2.43,2.59-3.39,2.61L522.13,80.85 M535.63,56.17l-3.98,10.56h7.83L535.63,56.17"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M247.32,80.84l-1.52,0V64.55L235,45.55l2.31-0.01c0.75,0,2.6,0.2,3.59,1.99c0.68,1.25,7.06,12.59,7.06,12.59 l7.06-12.59c1.08-1.95,3.62-1.98,3.65-1.98l2.13,0L250,64.53v13.4C250,79.82,248.62,80.84,247.32,80.84"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M264.86,80.85l13.56-36.14l13.26,36.14l-1.69-0.01c-0.11,0-2.66-0.03-3.54-2.66l-2.83-7.45h-10.74l-0.06,0.14 l-0.01-0.01c-0.83,2.14-2.78,7.16-2.84,7.35c-0.64,2.01-2.44,2.59-3.39,2.61L264.86,80.85 M278.36,56.17l-3.98,10.56h7.83 L278.36,56.17"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M343.33,80.84h-1.52c-1.31,0-2.69-1.02-2.69-2.91V49.61h-7.67c-1.52,0-2.37-1.3-2.39-2.52l-0.02-1.55h22 c1.52,0,2.36,1.31,2.39,2.53l0.02,1.53h-10.12V80.84"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M310.87,80.84h-1.52c-1.3,0-2.69-1.02-2.69-2.91V49.61h-7.68c-1.52,0-2.36-1.3-2.39-2.52l-0.02-1.55h22 c1.52,0,2.36,1.31,2.39,2.53l0.01,1.53h-10.11V80.84"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M223.4,80.84l-1.52-0.01c-1.32-0.03-2.65-1-2.65-2.78l0-12.86H205.6v12.74c0,1.91-1.37,2.91-2.72,2.91 l-1.45-0.04l0-35.27l1.5,0.01c1.33,0.03,2.68,1,2.68,2.78l0,12.83h13.63l0.01-12.71c0-1.89,1.38-2.91,2.67-2.91l1.49,0.05 L223.4,80.84"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M400.32,80.85h-24.75l18.74-31.28h-13.35c-1.75,0-2.7-1.33-2.72-2.58l-0.01-1.43h23.14l-18.72,31.31h14.91 c0.79,0,1.44,0.24,1.94,0.7c0.8,0.76,0.81,1.78,0.81,1.9V80.85"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M213.85,203.72h-2.83l-1.02-2.62H205l-1.03,2.62h-2.76l6.23-14.06h0.17L213.85,203.72 M207.51,194.76 l-1.68,4.24h3.32L207.51,194.76"
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="230.08,203.72 221.85,203.72 221.85,189.78 224.61,189.78 224.61,201.11 230.08,201.11 230.08,203.72 "
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="246.72,203.72 238.49,203.72 238.49,189.78 241.24,189.78 241.24,201.11 246.72,201.11 246.72,203.72 "
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="272.24,203.72 269.48,203.72 269.48,189.78 272.24,189.78 272.24,203.72 "
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="293.15,203.98 284.64,195.94 284.64,203.72 281.89,203.72 281.89,189.55 282.05,189.52 290.44,197.51 290.44,189.78 293.19,189.78 293.19,203.97 293.15,203.98 "
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M309.55,203.95c-4.28,0-7.26-2.96-7.26-7.19c0-4.25,2.99-7.22,7.26-7.22c1.38,0,2.76,0.4,4.01,1.13l-0.83,2.41 c-1.04-0.56-2.12-0.86-3.15-0.86c-2.68,0-4.34,1.74-4.34,4.54c0,2.8,1.66,4.54,4.34,4.54c1.07,0,2.29-0.36,3.29-0.96l0.81,2.41 C312.54,203.5,311,203.95,309.55,203.95"
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="330.64,203.72 322.41,203.72 322.41,189.78 325.16,189.78 325.16,201.11 330.64,201.11 330.64,203.72 "
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M343.85,203.95c-3.61,0-5.51-2.03-5.51-5.86v-8.31h2.75v8.07c0,2.25,1.01,3.5,2.84,3.5 c1.82,0,2.81-1.25,2.81-3.5v-8.07h2.75v8.31C349.49,201.87,347.49,203.95,343.85,203.95"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M362.24,203.95c-1.37,0-3.13-0.55-4.35-1.35l0.74-2.57c1.13,0.79,2.49,1.26,3.7,1.26 c1.58,0,1.75-1.03,1.75-1.47c0-0.96-0.6-1.51-2.57-2.34c-2.12-0.9-3.11-2.18-3.11-4.02c0-1.88,1.44-3.92,4.6-3.92 c1.42,0,2.81,0.5,3.58,1l-0.68,2.54c-0.82-0.55-1.97-1.05-2.89-1.05c-1.35,0-1.83,0.75-1.83,1.38c0,1.09,0.79,1.44,2.53,2.22 c2.65,1.18,3.19,2.48,3.19,4.08C366.89,201.82,365.45,203.95,362.24,203.95"
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="378.54,203.72 375.78,203.72 375.78,189.78 378.54,189.78 378.54,203.72 "
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="393.07,203.84 392.96,203.84 386.63,189.78 389.8,189.78 393.05,197.89 396.3,189.78 399.39,189.78 393.07,203.84 "
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="415.62,203.72 407.4,203.72 407.4,189.78 415.62,189.78 415.62,192.4 410.15,192.4 410.15,195.41 415.14,195.41 415.14,197.87 410.15,197.87 410.15,201.11 415.62,201.11 415.62,203.72 "
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M448.54,203.72h-3.1l-2.34-4.7l-0.14,0.02l-0.5,0.02h-0.02l-0.87-0.02l-0.51-0.02v4.72h-2.75v-13.83 c1.29-0.14,2.48-0.21,3.82-0.21c3.63,0,5.72,1.67,5.72,4.55c0,1.63-0.82,3.08-2.25,3.96l-0.18,0.11L448.54,203.72 M442.5,192.23 l-1.26,0.1l-0.17,0.03v4.35l0.19,0.02l1.03,0.06c1.68,0,2.69-0.91,2.69-2.44C444.96,193.06,444,192.23,442.5,192.23"
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="465.19,203.72 456.96,203.72 456.96,189.78 465.19,189.78 465.19,192.4 459.71,192.4 459.71,195.41 464.7,195.41 464.7,197.87 459.71,197.87 459.71,201.11 465.19,201.11 465.19,203.72 "
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M477.93,203.95c-1.37,0-3.13-0.55-4.36-1.35l0.74-2.57c1.13,0.79,2.49,1.26,3.71,1.26 c1.58,0,1.75-1.03,1.75-1.47c0-0.96-0.6-1.51-2.57-2.34c-2.12-0.9-3.11-2.18-3.11-4.02c0-1.88,1.44-3.92,4.6-3.92 c1.42,0,2.81,0.5,3.58,1l-0.69,2.54c-0.83-0.55-1.98-1.05-2.89-1.05c-1.35,0-1.83,0.75-1.83,1.38c0,1.09,0.79,1.44,2.53,2.22 c2.65,1.18,3.19,2.48,3.19,4.08C482.59,201.82,481.15,203.95,477.93,203.95"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M497.46,203.95c-3.96,0-6.72-2.96-6.72-7.19c0-4.25,2.82-7.22,6.85-7.22c3.96,0,6.72,2.97,6.72,7.22 C504.31,200.93,501.43,203.95,497.46,203.95 M497.51,192.09c-2.25,0-3.82,1.92-3.82,4.67s1.57,4.67,3.82,4.67 c2.25,0,3.82-1.92,3.82-4.67S499.76,192.09,497.51,192.09"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M523.65,203.72h-3.1l-2.34-4.7l-0.14,0.02l-0.5,0.02h-0.02l-0.88-0.02l-0.51-0.02v4.72h-2.75v-13.83 c1.29-0.14,2.48-0.21,3.82-0.21c3.63,0,5.72,1.67,5.72,4.55c0,1.63-0.82,3.08-2.24,3.96l-0.18,0.11L523.65,203.72 M517.61,192.23 l-1.26,0.1l-0.17,0.03v4.35l0.19,0.02l1.02,0.06c1.68,0,2.68-0.91,2.68-2.44C520.08,193.06,519.11,192.23,517.61,192.23"
    />
    <polyline
      :fill="fill ? '#808184' : 'currentColor'"
      points="537.62,203.72 534.87,203.72 534.87,192.4 530.77,192.4 530.77,189.78 541.72,189.78 541.72,192.4 537.62,192.4 537.62,203.72 "
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M553.34,203.95c-1.37,0-3.13-0.55-4.35-1.35l0.74-2.57c1.13,0.79,2.49,1.26,3.71,1.26 c1.58,0,1.75-1.03,1.75-1.47c0-0.96-0.6-1.51-2.57-2.34c-2.12-0.9-3.11-2.18-3.11-4.02c0-1.88,1.44-3.92,4.6-3.92 c1.42,0,2.81,0.5,3.58,1l-0.68,2.54c-0.83-0.55-1.98-1.05-2.9-1.05c-1.35,0-1.83,0.75-1.83,1.38c0,1.09,0.79,1.44,2.53,2.22 c2.65,1.18,3.19,2.48,3.19,4.08C558,201.82,556.56,203.95,553.34,203.95"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M556.85,49.58h-0.77l-0.05-2.2l-1.12,1.66h-0.09l-1.14-1.66l-0.04,2.2h-0.79l0.18-4.12h0.1l1.74,2.47l1.7-2.47 h0.11L556.85,49.58 M550.91,49.58h-0.8v-3.31h-1.18v-0.77h3.17v0.77h-1.18V49.58"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="M488.92,131h-0.77l-0.05-2.2l-1.12,1.66h-0.09l-1.14-1.66l-0.04,2.2h-0.79l0.18-4.12h0.11l1.73,2.47l1.7-2.47 h0.11L488.92,131 M482.99,131h-0.8v-3.31H481v-0.77h3.17v0.77h-1.18V131"
    />
    <path
      :fill="fill ? '#eaeae9' : 'currentColor'"
      d="M172.26,203.72h-4.22l10.99-18.28c0,0,0,7.07,0,11.24C179.04,201.15,177.46,203.72,172.26,203.72"
    />
    <path
      :fill="fill ? '#b2b2b3' : 'currentColor'"
      d="M138.69,203.72h-6.94c-0.78,0-1.61-0.36-1.61-1.32c0-1.25,1.68-1.95,1.68-1.95c6.05-2.69,13.19-7.6,18.77-16.53 L138.69,203.72"
    />
    <polyline
      :fill="fill ? '#d4d6d7' : 'currentColor'"
      points="158.27,203.72 148.43,203.72 179.03,152.84 179.03,169.2 158.27,203.72 "
    />
    <path
      :fill="fill ? '#c7c7c9' : 'currentColor'"
      d="M148.43,203.72h-9.74l11.91-19.8l7.07-11.75l6.01-10c4.51-7.52,10.11-12.29,15.36-15.31v5.98l0,0L148.43,203.72 "
    />
    <polyline
      :fill="fill ? '#e1e1e1' : 'currentColor'"
      points="41.23,74 41.23,57.75 41.23,57.75 52.22,39.47 52.22,39.47 52.22,39.47 62,39.47 41.23,74 "
    />
    <path
      :fill="fill ? '#eaeae9' : 'currentColor'"
      d="M41.23,57.75L41.23,57.75c0,0,0-7.07,0-11.24c0-4.46,1.58-7.04,6.78-7.04h4.22h0L41.23,57.75"
    />
    <path
      :fill="fill ? '#b2b2b3' : 'currentColor'"
      d="M69.76,59.11l11.81-19.64h6.94c0.78,0,1.61,0.36,1.61,1.31c0,1.25-1.69,1.95-1.69,1.95 C82.42,45.41,75.34,50.28,69.76,59.11"
    />
    <polyline
      :fill="fill ? '#d4d6d7' : 'currentColor'"
      points="41.23,90.36 41.23,74 62,39.47 71.84,39.47 71.84,39.47 41.23,90.36 "
    />
    <path
      :fill="fill ? '#c7c7c9' : 'currentColor'"
      d="M41.23,96.34v-5.98l30.61-50.88h0h9.74L69.76,59.11L62.6,71.02l-6.01,10C52.08,88.55,46.47,93.31,41.23,96.34"
    />
    <polyline
      :fill="fill ? '#858183' : 'currentColor'"
      points="104.93,162.66 104.93,162.66 104.93,162.66 95.18,162.66 119.88,121.6 129.63,121.6 129.63,121.6 104.93,162.66 "
    />
    <path
      :fill="fill ? '#7e7a7b' : 'currentColor'"
      d="M95.18,162.66L95.18,162.66L95.18,162.66L95.18,162.66 M95.18,162.66L95.18,162.66h-9.7l24.66-41.06h9.75 L95.18,162.66"
    />
    <polyline
      :fill="fill ? '#a19fa1' : 'currentColor'"
      points="85.47,162.66 85.47,162.66 85.48,162.66 75.68,162.66 100.38,121.6 100.39,121.6 100.39,121.6 110.13,121.6 85.48,162.66 85.48,162.66 85.47,162.66 "
    />
    <polyline
      :fill="fill ? '#959395' : 'currentColor'"
      points="75.68,162.66 65.93,162.66 90.63,121.6 100.38,121.6 75.68,162.66 75.68,162.66 75.68,162.66 "
    />
    <polyline
      :fill="fill ? '#949294' : 'currentColor'"
      points="60.72,203.72 50.98,203.72 75.68,162.66 75.68,162.66 75.68,162.66 75.68,162.66 85.48,162.66 85.47,162.66 60.72,203.72 "
    />
    <polyline
      :fill="fill ? '#a19fa1' : 'currentColor'"
      points="50.98,203.72 41.23,203.72 65.93,162.66 66.15,162.66 75.68,162.66 75.68,162.66 50.98,203.72 "
    />
    <path
      :fill="fill ? '#736d6e' : 'currentColor'"
      d="M72.12,201l23.06-38.33l0,0h0h9.75c0,0,0,0,0,0c-0.1,0.17-6.51,10.78-12.31,20.46 C86.54,193.25,78.61,198.39,72.12,201"
    />
    <path
      :fill="fill ? '#abaaab' : 'currentColor'"
      d="M60.72,203.72l24.76-41.06l0,0h0h9.7l0,0L72.12,201C65.77,203.54,60.72,203.72,60.72,203.72"
    />
    <polyline
      :fill="fill ? '#858183' : 'currentColor'"
      points="100.39,121.6 100.38,121.6 90.63,121.6 90.63,121.6 115.34,80.53 115.34,80.53 125.08,80.53 100.39,121.6 100.39,121.6 100.39,121.6 "
    />
    <polyline
      :fill="fill ? '#7e7a7b' : 'currentColor'"
      points="110.13,121.6 110.13,121.6 110.13,121.6 100.39,121.6 100.39,121.6 125.08,80.53 125.09,80.53 134.79,80.53 110.13,121.6 "
    />
    <polyline
      :fill="fill ? '#a19fa1' : 'currentColor'"
      points="119.88,121.6 119.88,121.6 110.13,121.6 134.79,80.53 144.58,80.53 119.88,121.6 "
    />
    <polyline
      :fill="fill ? '#959395' : 'currentColor'"
      points="129.63,121.6 119.88,121.6 144.58,80.53 144.59,80.53 154.12,80.53 154.34,80.53 129.63,121.6 "
    />
    <polyline
      :fill="fill ? '#949294' : 'currentColor'"
      points="144.59,80.53 144.58,80.53 134.79,80.53 159.55,39.47 159.55,39.47 169.29,39.47 144.59,80.53 "
    />
    <polyline
      :fill="fill ? '#a19fa1' : 'currentColor'"
      points="154.34,80.53 154.34,80.53 154.12,80.53 144.59,80.53 169.29,39.47 179.04,39.47 154.34,80.53 "
    />
    <path
      :fill="fill ? '#736d6e' : 'currentColor'"
      d="M125.08,80.53L125.08,80.53h-9.75l0,0c0,0,6.47-10.7,12.31-20.46c6.07-10.13,14.01-15.26,20.5-17.87 L125.08,80.53"
    />
    <path
      :fill="fill ? '#abaaab' : 'currentColor'"
      d="M134.79,80.53L134.79,80.53h-9.7l23.06-38.33c6.35-2.55,11.4-2.73,11.4-2.73l0,0L134.79,80.53"
    />
    <polyline
      :fill="fill ? '#e1e1e1' : 'currentColor'"
      points="168.04,203.72 158.27,203.72 179.04,169.2 179.04,185.45 168.04,203.72 "
    />
  </svg>
</template>
